




















































































































































































































































































































































































































import {Goods} from "@/request/goods/Goods";
import {Category} from "@/request/goods/Category";
import {ActivityInfo} from "@/request/marketing/ActivityInfo";
import {MemberSet} from "@/request/customer/MemberSet";
import {Delivery} from "@/request/after-sale/Delivery";
import {DistributionSet} from "@/request/marketing/DistributionSet";
import AddGoodsStore from './AddGoodsStore';
import {Utils} from "@/common/Utils";
import ModuleType from "@/common/ModuleType";

let secStandard = {
    desc: '',
    num: 1,
    price: null,
    reducedPrice: null,
    inPrice: null,
    startMemberLv: 0
};

let standard = {
    id: '',
    nameFirst: '',
    nameSec: '',
    image: '',
    name: '',
    secStandards: [
        {...secStandard}
    ]
};

let formData = {
    headImages: [],
    name: '',
    cover: '',
    categoryFirstId: '',
    categorySecId: '',
    code: '',
    status: 0,
    deliveryStatus: 0, //配送方式 0: 包邮
    distributionStatus: 0, //参与分销
    distributionSetId: '', //分销模版
    detail: '',
    flags: [],
    memberLv: '',
    bintegralDeduction: false,
    activityType: 0,
    activitySelect: {
        id: '',
        num: 1,
        price: 1
    },
    deliveryTemplateId: '',
    // property: [], //属性
    standards: [
        {...standard}
    ]
};

export default {
    data() {
        let self: any = this;
        return {
            remoteLoading: false,
            formData: {...self.assignObject(formData)},
            categoryList: [],
            categorySecList: [],
            standardList: [],
            standardSecList: [],
            activityInfoList: [], //活动
            memberList: [], //会员列表
            summaryList: [], //运费模版列表
            DistributionSetList: [], //分销模版
            dateOptions: {
                disabledDate (date: any) {
                    return date && date.valueOf() < Date.now() - 86400000;
                }
            }
        }
    },
    created() {
        let self: any = this;
    },
    activated() {
        let self: any = this;
        self.loadCategory();
        Promise.all([
            Goods.standardList(),
            Goods.standardList(1),
            MemberSet.getAll(),
            Delivery.summaryList(),
            DistributionSet.getAll()
        ]).then((res: Array<any>) => {
            if (res[0].code === 0) {
                self.standardList = res[0].data;
            }
            if (res[1].code === 0) {
                self.standardSecList = res[1].data;
            }
            if (res[2].code === 0) {
                self.memberList = res[2].data;
                self.memberList = self.memberList.filter((j: any) => j.lv > 0);
            }
            if (res[3].code === 0) {
                self.summaryList = res[3].data;
            }
            if (res[4].code === 0) {
                self.DistributionSetList = res[4].data;
            }
            if (AddGoodsStore.getters.goods) {
                self.formData = AddGoodsStore.getters.goods;
                // self.formData.standards.forEach((j: any) => j.secStandards.forEach((k: any) => k.inPrice2 = k.inPrice));
                self.formData.activitySelect = self.formData.activitySelect? self.formData.activitySelect: {
                    id: '',
                    num: 1,
                    price: 1
                };
                self.loadCategorySec(self.formData.categoryFirstId);
                setTimeout(() => self.$refs['el-editor'].setHtml(self.formData.detail), 1000);
            }
        });
        self.activityRemoteSearch('');
    },
    destroyed () {
        AddGoodsStore.commit('goods', undefined);
    },
    methods: {
        loadCategory() {
            let self: any = this;
            Category.getCategoryList().then((body: any) => {
                if (body['code'] === 0) {
                    self.categoryList = body.data;
                }
            })
        },
        loadCategorySec(id: string) {
            let self: any = this;
            if (id) {
                Category.getCategorySecList(id).then((body: any) => {
                    if (body['code'] === 0) {
                        self.categorySecList = body.data;
                    }
                })
            }
        },
        addStandard() {
            let self: any = this;
            self.formData.standards.push(self.assignObject(standard));
        },
        addSecStandard(secStandards: any) {
            let self: any = this;
            secStandards.push(self.assignObject(secStandard));
        },
        activityRemoteSearch(query: string) {
            let self: any = this;
            self.remoteLoading = true;
            ActivityInfo.getAll(`?name=${query}`).then((body: any) => {
                self.remoteLoading = false;
                self.activityInfoList = body.data;
            })
        },
        submit() {
            let self: any = this;
            let params = self.assignObject(self.formData);
            params.standards.forEach((j: any) => {
                j.secStandards.forEach((k: any) => {
                    k.price = k.price * 10 * 10;
                    if (k.reducedPrice === null) {
                        delete k.reducedPrice
                    } else {
                        k.reducedPrice = k.reducedPrice * 10 * 10;
                    }
                    if (k.inPrice === null) {
                        delete k.inPrice;
                    } else {
                        k.inPrice = k.inPrice * 10 * 10;
                    }
                })
            });
            Goods.save(params).then((body: any) => {
                if (body['code'] === 0) {
                    self.reset();
                    self.$Message.info('保存成功');
                }
            })
        },
        reset() {
            let self: any = this;
            self.$refs['el-editor'].setHtml('');
            self.formData = {...self.assignObject(formData)};
            delete self.formData.id;
            AddGoodsStore.commit('goods', undefined);
        },
        activityTypeChange() {
            let self: any = this;
            self.formData.standards.forEach((j: any) => {
                j.secStandards.forEach((k: any) => {
                    k.inPrice = null;
                })
            })
        }
    }
}
