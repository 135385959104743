import {BaseRequest} from "@/request/BaseRequest";

class Delivery extends BaseRequest {

    public requestPath: string = '/after_sale/deliveryTemplate';

    /**
     * 获取快递模板摘要列表(全部模板全给过去,限制最多10个模板)(1.1新增)
     */
    summaryList(): any {
        return this.get(`${this.requestPath}/summaryList`);
    }

}

const c = new Delivery();
export {c as Delivery};